.info {
  /* padding: 1rem; */
  border-left: var(--thick) solid var(--secondary-dark);
  box-shadow: -4px -10px 5px var(--alm-light);
}
.time_box {
  background-color: var(--secondary);
  color: var(--light) !important;
  border-radius: var(--rad-md);
  min-width: 0;
}

.pos_link {
  text-decoration: underline;
}
.map_marker {
  color: var(--secondary);
}
