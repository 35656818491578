.root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.container {
  position: relative;
  left: 20px;
  top: 20px;

  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 300px;
  padding: 20px;
  background-color: var(--alm-light);
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.success {
  background-color: var(--success);
}

.error {
  background-color: var(--error);
  color: var(--light);
}

.warning {
  background-color: var(--warning);
}

.info {
  background-color: var(--info);
}

.progressBarContainer {
  background-color: rgba(0, 0, 0, 0.2);
  height: 4px;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
}

.progressBar {
  background-color: var(--primary);
  height: 100%;
  width: 0;
}
