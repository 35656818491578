/*
    Profile form
*/
.Profile {
  text-align: center;
}

.Profile-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Profile-logo {
    animation: Profile-logo-spin infinite 20s linear;
  }
}

.Profile-header {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes Profile-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* .profileButton {
    background-color: var(--joker);
    color: var(--primary);
} */

/* STYLE THIS properly later
form {
    margin: 0rem auto 2rem;
    /* width: 24rem; 
    width: 100%;
}

label {
    display: block;
    color: #fff;
}

input {
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    width: inherit;
    height: 2rem;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type="submit"] {
    height: 2.5rem;
    font-size: 1rem;
    color: #fff;
    background: #0971f1;
    cursor: pointer;
}

.col-centered {
    float: none;
    margin: 0 auto;
}


/* p {
    font-size: 1rem;
    margin: 0;
    color: #ff9900;
} */
