.loading {
  text-align: center;
  height: 100%;
}

.loading-header {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--f1);
}
