.admin-nav-parent .navbar {
  background-color: white;
  border-bottom: solid 1px #ebebeb;
}
.admin-nav-parent .bottom-tab-nav {
  border-top: solid 1px #ebebeb;
}
.admin-nav-parent .nav-link,
.bottom-nav-link {
  /*color: #b5b8be;*/
  color: var(--secondary-dark);
}
.admin-nav-parent .bottom-nav-link.active div {
  /*color: #000000;*/
  border-top: var(--thick) solid;
}
.admin-nav-parent .bottom-nav-link:hover {
  /*color: rgb(85, 85, 85);*/
  cursor: pointer;
}
.admin-nav-parent .bottom-tab-label {
  font-size: var(--f4);
}
