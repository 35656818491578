#root {
  /* Constants */
}
.add-booking-section {
  height: 100%;
}
/*Next and back Button*/
.add-booking-section .back-btn {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 5%;
  margin: 0;
  background-color: transparent;
  color: var(--secondary-dark);
  border: none;
}
.add-booking-section .back-btn p {
  margin-bottom: 0;
}
.add-booking-section .def-next-btn {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 5%;
  margin: 0;
  background-color: transparent;
  color: var(--secondary-dark);
  border: none;
}
