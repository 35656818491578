.animation_wrapper {
  animation: 1s fade 4s linear forwards;
  background-color: var(--primary);
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: var(--darker);
}

.animation_header {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--f1);

  position: absolute;
  animation: zoom-out-out 1s ease-out;
}

@keyframes zoom-out-out {
  0% {
    transform: scale(50, 50);
  }
  100% {
    transform: scale(0, 0);
  }
}

.animation_header2 {
  animation-delay: 10s;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: var(--f1);
  animation: zoom-out-out-delay 1s ease-out;
}

@keyframes zoom-out-out-delay {
  0% {
    transform: scale(400, 400);
  }
  50% {
    transform: scale(150, 150);
  }
  100% {
    transform: scale(1, 1);
  }
}

.logo_emerge {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  animation: 500ms emerge 0s linear forwards;
  /* animation: 500ms emerge 1500ms linear forwards; */
}

@keyframes emerge {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}
