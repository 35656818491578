.mapContainaer {
  width: var(--large-w);
  height: var(--tall-h);
}

.mapBox {
  opacity: 0.7;
  background-color: var(--secondary-dark);
  color: var(--light);
  border-radius: var(--rad-sm);
}

.saved_loc_parent {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 8px;
}

.saved_loc {
  border-radius: var(--rad-md);
  background-color: var(--secondary-dark);
  color: var(--secondary);
  height: var(--min-h);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  font-family: "Roboto";
  font-weight: 500;
  padding: 0.8rem;
}

.nxt_btn_parent {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.nxt_btn {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
  border-radius: var(--rad-lg) !important;
  z-index: 5;
}

.next_button {
  color: #01613b !important;
  font-size: 1rem !important;
  padding: 9px 40px !important;
  border-radius: 20px !important;
  background-color: #01cc7b !important;
  height: min-content !important;
}

.address_text {
  color: #2a2a2a !important;
  width: 100% !important;
  font-weight: 500 !important;
  padding-bottom: 2px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding-bottom: 1px !important;
}

.coordinates_text {
  color: #959595 !important;
  width: max-content !important;
  font-size: 0.7rem !important;
  padding-bottom: 1px !important;
}

.loading_placeholder {
  animation: opacity_enter 300ms;
}

@keyframes opacity_enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.position_select_root {
  position: absolute;
  bottom: 4%;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.position_select_size_wrapper {
  width: min(max-content, 100vw);
  min-width: min(90%, 26rem);
  max-width: 98%;
}

.position_select_panel {
  background-color: #fcfaf9;
  padding: 17px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 16%) 0 0 14px -2px;
  column-gap: 10px;
  max-width: 100vw;
  min-width: min(26rem, 100%);
}

.position_select_info {
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  min-width: 0;
  flex-grow: 1;
}
