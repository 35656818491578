.home {
  /* text-align: center; */
  height: 100%;
}

.title {
  color: var(--secondary-dark) !important;
}

.home-header {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
