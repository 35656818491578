.root {
  background-color: var(--primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputContainer {
  max-width: 500px;
  margin: 0 auto;
}

/* .Registration-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .Registration-logo {
    animation: Registration-logo-spin infinite 20s linear;
  }
} */

/* .Registration-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

/* .Registration-link {
  color: var(--joker);
} */
/* 
@keyframes Registration-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

form {
  width: 100%;
}

label {
  display: block;
}

input {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  width: inherit;
  height: 2rem;
  border: none;
  border-radius: var(--rad-sm);
  box-sizing: border-box;
}

input[type="submit"] {
  height: 2.5rem;
  font-size: var(--f4);
  cursor: pointer;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.registrationButton {
  margin-bottom: 5em;
} */

/* p {
    font-size: 1rem;
    margin: 0;
    color: #ff9900;
} */
